import {
  UNSET_ERROR,
  SET_ERROR,
  SET_LOADING,
  SET_AB_TESTING,
  UNSET_AB_TESTING,
} from './actionTypes';

export const setError = (payload: any) => ({
  type: SET_ERROR,
  payload,
});

export const unsetError = () => ({
  type: UNSET_ERROR,
});

export const setLoading = (payload: any) => ({
  type: SET_LOADING,
  payload,
});

export const setABTesting = (payload: any) => ({
  type: SET_AB_TESTING,
  payload,
});

export const unsetABTesting = () => ({
  type: UNSET_AB_TESTING,
});
