/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';

import { HtmlSanitizer } from '@BICE_Vida/scp-components';
import { availablePlanCard } from '@/utils/generalTexts';
import { Text } from '../../designSystem';

import styles from './AvailablePlanCard.module.scss';

import { AvailablePlan, Badges } from '../../types/availablePlans';
import { UF } from '../../types/uf';
import { useCyberDates } from '../../customHooks/index';

type AvailablePlanCardHeaderT = {
  availablePlan: AvailablePlan;
  UF: UF;
};

const AvailablePlanCardHeader = ({ availablePlan, UF }: AvailablePlanCardHeaderT) => {
  const { isPromo, isCyberDayToday } = useCyberDates(new Date());

  const descriptionsParts = availablePlan.description.split('+');
  const planDescription = `${descriptionsParts[0]} ${
    !!descriptionsParts[2]
      ? `+ <br/> <span>${descriptionsParts[1]} + ${descriptionsParts[2]} </span>`
      : !!descriptionsParts[1]
      ? `<span>+${descriptionsParts[1]}</span>`
      : ''
  }`;

  const Badge = ({ label, icon, background, color }: Badges) => {
    return (
      <div
        className={styles.card_chip}
        style={{
          backgroundColor: `#${background}`,
          color: `#${color}`,
        }}
      >
        <i>{icon}</i>
        <HtmlSanitizer html={label} allowedTags={['b']} />
      </div>
    );
  };

  return (
    <Fragment>
      <div className={styles.chip_section}>
        {isPromo || isCyberDayToday
          ? availablePlanCard.cyberText.chips[
              availablePlan.base as 'standard' | 'premium' | 'elite'
            ].map((chip, i) => <Badge {...chip} key={`badge_${chip.label}_${i}`} />)
          : availablePlan.badges
              ?.filter((badge) => badge.show === true)
              .map((badge, i) => <Badge {...badge} key={`badge_${badge.label}_${i}`} />)}
      </div>
      <div className={styles.available_plan_card_description_container}>
        <Text text={planDescription} customClass={styles.available_plan_card_description} />
        <Text text={availablePlanCard.productName} customClass={styles.available_plan_card_name} />
      </div>
      <HtmlSanitizer
        className={styles.available_card_price}
        html={availablePlanCard.priceDescription(availablePlan.price.uf, UF.value)}
        allowedTags={['span', 'strong']}
      />
      <Text text={availablePlanCard.monthlyWorkers} customClass={styles.title_text} />
    </Fragment>
  );
};

export default AvailablePlanCardHeader;
