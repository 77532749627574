import React, { Fragment } from 'react';
import classnames from 'classnames';

import { HtmlSanitizer } from '@BICE_Vida/scp-components';

import Text from '../../designSystem/Text';

import styles from './AvailablePlanCard.module.scss';

import { availablePlanCard } from '../../utils/generalTexts';

type AvailablePlanCardCoverageT = {
  cardCoverages: string[];
  discount: boolean;
};

const AvailablePlanCardCoverage = ({ cardCoverages, discount }: AvailablePlanCardCoverageT) => {
  const coverageSectionTitleClasses = classnames(
    styles.title_text,
    styles.coverage_section_title,
    discount ? styles.withDiscount : styles.withoutDiscount
  );

  return (
    <Fragment>
      <Text text={availablePlanCard.coveragesTitle} customClass={coverageSectionTitleClasses} />
      {!!cardCoverages?.length &&
        cardCoverages.map((value) => {
          return (
            <div className={styles.coverage_details_group} key={value}>
              <span className={styles.coverage_check} />
              <HtmlSanitizer
                className={styles.modal_message}
                html={value}
                tag="span"
                allowedTags={['b']}
              />
            </div>
          );
        })}

      <div>
        <HtmlSanitizer
          className={styles.plan_types_card_optional}
          html={availablePlanCard.optional}
          tag="p"
          allowedTags={['span']}
        />
      </div>
    </Fragment>
  );
};

export default AvailablePlanCardCoverage;
