import React from 'react';

import { HtmlSanitizer } from '@BICE_Vida/scp-components';

import { Text } from '@BICE_Vida/components';
import styles from './AvailablePlanCard.module.scss';
import { Badges } from '@/src/types/availablePlans';
import Icon from '@/designSystem/Icon';
import { availablePlanCard } from '@/utils/generalTexts';

type AvailablePlanCyber = {
  base: 'standard' | 'premium' | 'elite';
};

function AvailablePlanCyber({ base }: AvailablePlanCyber) {
  const Badge = ({ label, background, color }: Omit<Badges, 'icon' | 'show'>) => {
    return (
      <div
        className={styles.card_chip}
        style={{
          backgroundColor: `#${background}`,
          color: `#${color}`,
        }}
      >
        <HtmlSanitizer html={label} allowedTags={['b']} />
      </div>
    );
  };

  return (
    <div
      className={base !== 'elite' ? styles.assistance_container_base : styles.assistance_container}
    >
      <Badge label="CYBER SAFE" color="4CEC57" background="000000" />

      <div className={styles.container_details_cyber_discounts}>
        <div className={styles.container_icon_cyber}>
          <Icon name="attach_money" customClass={styles.icon_cyber} size="medium" />
        </div>
        <div className={styles.container_text}>
          <Text tag="p" fontTypo="title-small" color="black">
            {availablePlanCard.cyberText.discounts[base]}
          </Text>
          <Text fontTypo="body-small">{availablePlanCard.cyberText.sinceMonthText}</Text>
        </div>
      </div>
    </div>
  );
}

export default AvailablePlanCyber;
