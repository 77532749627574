import { gql } from '@apollo/client';
import planFragment from '../fragments/planFragment';
import ufFragment from '../fragments/ufFragment';

export default gql`
  query listPlansAndUFToday($brokerCode: String) {
    listPlans(brokerCode: $brokerCode) {
      ...planFragment
    }

    getUFToday {
      ...ufFragment
    }
  }
  ${planFragment}
  ${ufFragment}
`;
