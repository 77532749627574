import React from 'react';

import Image from 'next/image';

import { HtmlSanitizer } from '@BICE_Vida/scp-components';
import styles from './AvailablePlanCard.module.scss';
import { ICON_PROMO } from '@/utils/assets';
import { AvailablePlan } from '@/src/types/availablePlans';

function AvailablePlanPromo(props: AvailablePlan['promo']) {
  if (!props) return null;
  const { description, label, icon } = props;

  return (
    <div className={styles.promo_container}>
      {!!label && <span className={styles.card_chip}>{label}</span>}
      <div className={styles.card_promo_section}>
        <Image src={icon || ICON_PROMO} width={28} height={28} alt="promoción" />
        <HtmlSanitizer
          className={styles.modal_message}
          html={description}
          tag="p"
          allowedTags={['b']}
        />
      </div>
    </div>
  );
}

export default AvailablePlanPromo;
