import React, { Fragment, useState } from 'react';
import classnames from 'classnames';

import AvailablePlanAssistances from '@/components/AvailablePlanCard/AvailablePlanAssistances';
import Card from '../../designSystem/Card';
import AvailablePlanModal from '../AvailablePlanModal';
import AvailablePlanCardHeader from './AvailablePlanCardHeader';
import AvailablePlanCardCoverage from './AvailablePlanCardCoverage';

import styles from './AvailablePlanCard.module.scss';

import { AvailablePlan } from '../../types/availablePlans';
import { UF } from '../../types/uf';
import AvailablePlanPromo from './AvailablePlanPromo';
import AvailablePlanCyber from './AvailablePlanCyber';
import { useCyberDates } from '@/src/customHooks';

type AvailablePlanCardT = {
  id: string;
  idx: number;
  availablePlan: AvailablePlan;
  UF: UF;
  customClass?: string;
  selectable?: boolean;
  pagePrefix?: string;
  btnAction: {
    id: string;
    text: string;
    click?: () => void;
    onClickEvent?: () => void;
  };
  planSelected?: string;
  setPlanSelected?: (availablePlanId: string, planType: string) => void;
  mobile?: boolean;
};

const AvailablePlanCard = ({
  id,
  idx,
  availablePlan,
  UF,
  customClass,
  selectable = false,
  pagePrefix = '',
  btnAction,
  planSelected = '',
  setPlanSelected = () => {},
  mobile = false,
}: AvailablePlanCardT) => {
  const { isPromo, isCyberDayToday } = useCyberDates(new Date());

  const customClasses = classnames(styles.available_plan_card, {
    [`${customClass}`]: customClass,
  });
  const [showPlanDetails, setShowPlanDetails] = useState(false);

  const openPlanDetailsModal = () => {
    setShowPlanDetails(true);
    btnAction?.onClickEvent?.();
  };
  const closePlanDetailsModal = () => setShowPlanDetails(false);

  const btnActionCard = btnAction ? { ...btnAction, click: openPlanDetailsModal } : undefined;

  return (
    <Fragment>
      <AvailablePlanModal
        isOpen={showPlanDetails}
        handleClose={closePlanDetailsModal}
        plan={availablePlan}
        coverages={availablePlan.coverage}
        UF={UF}
        openPDF={btnAction?.click}
        theme="secondary"
      />

      <Card
        id={`${id}${idx}`}
        customClass={customClasses}
        bordered
        selectable={selectable}
        onClick={setPlanSelected}
        name={`${pagePrefix}${mobile ? '_mobile' : ''}`}
        selected={planSelected === availablePlan._id}
        btnAction={btnActionCard}
        btnActionClass={styles.btn_action}
        selector="radio"
      >
        <Fragment>
          <AvailablePlanCardHeader availablePlan={availablePlan} UF={UF} />
          <AvailablePlanCardCoverage
            cardCoverages={availablePlan.cardCoverages}
            discount={
              availablePlan.originalPrice
                ? availablePlan.originalPrice > availablePlan.price.uf
                : false
            }
          />
          {availablePlan.assistances?.length ? (
            <AvailablePlanAssistances assistances={availablePlan.assistances} />
          ) : null}
          {availablePlan.promo?.show && <AvailablePlanPromo {...availablePlan.promo} />}
          {(isPromo || isCyberDayToday) && (
            <AvailablePlanCyber base={availablePlan.base as 'standard' | 'premium' | 'elite'} />
          )}
        </Fragment>
      </Card>
    </Fragment>
  );
};

export default AvailablePlanCard;
