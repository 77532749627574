import React from 'react';

import { HtmlSanitizer } from '@BICE_Vida/scp-components';
import Image from 'next/image';
import { Assistance } from '@/src/types/availablePlans';
import { availablePlansSectionTexts } from '@/utils/generalTexts';

import styles from './AvailablePlanCard.module.scss';
import { assistancesImg } from '@/utils/constants';

type AvailablePlanAssistancesT = {
  assistances: Array<Assistance>;
};

function AvailablePlanAssistances({ assistances }: AvailablePlanAssistancesT) {
  const {
    assistances: { title },
  } = availablePlansSectionTexts;

  const filteredAssistances = assistances.slice(0, 2);

  return (
    <div className={styles.assistance_container}>
      <div className={styles.assistance_title}>{title}</div>
      <div className={styles.assistance_list}>
        {filteredAssistances.map(({ description, provider }) => {
          const img = assistancesImg[provider as keyof typeof assistancesImg];

          return (
            <div className={styles.assistance_item} key={description}>
              {img && <Image src={img} height={48} width={48} alt="asistencia" />}
              <HtmlSanitizer
                html={description}
                tag="span"
                allowedTags={['b']}
                className={styles.card_assistance_description}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AvailablePlanAssistances;
